import {
    createContext, PropsWithChildren, useContext, useMemo, useReducer,
} from 'react';
import { PortalConfiguration } from './types';

type Action = { type: 'setPortalConfiguration'; payload: PortalConfiguration };
type Dispatch = (action: Action) => void;
type State = PortalConfiguration | undefined;

const PortalConfigurationContext = createContext<{ configuration: State; dispatch: Dispatch } | undefined>(undefined);

function reducer(configuration: State, action: Action): State {
    if (action.type === 'setPortalConfiguration') {
        return { ...configuration, ...action.payload };
    }
    throw new Error(`Unhandled action type: ${action.type}`);
}

export function PortalConfigurationProvider({ children }: Readonly<PropsWithChildren>) {
    const [configuration, dispatch] = useReducer(reducer, undefined);

    const value = useMemo(() => ({ configuration, dispatch }), [configuration]);

    return (
        <PortalConfigurationContext.Provider value={value}>
            {children}
        </PortalConfigurationContext.Provider>
    );
}

export function usePortalConfiguration() {
    const context = useContext(PortalConfigurationContext);
    if (context === undefined) {
        throw new Error('usePortalConfiguration must be used within a PortalConfigurationProvider');
    }
    return context;
}

export async function loadConfiguration(dispatch: Dispatch, publicId: string) {
    const response = await fetch(`/service/config/${publicId}`);
    const configuration = await response.json();
    dispatch({ type: 'setPortalConfiguration', payload: configuration });
}
