import React from 'react';
import './App.css';
import './fontAwesome';
import {
    BrowserRouter, Navigate, Route, Routes, useParams,
} from 'react-router-dom';
import MainCustomerView from './pages/endCustomerPortal/MainCustomerView';
import { PortalConfigurationProvider } from './configuration/PortalConfigurationContext';
import PortalConfigurationLoader from './configuration/PortalConfigurationLoader';

function RedirectWithUUID() {
    const { uuid } = useParams();
    return <Navigate to={`/portal/${uuid}`} replace />;
}

function App() {
    return (
        <div className="wrapper">
            <PortalConfigurationProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<PortalConfigurationLoader />}>
                            <Route path="p/:uuid" element={<RedirectWithUUID />} />
                            <Route path="portal/:uuid" element={<MainCustomerView />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </PortalConfigurationProvider>
        </div>
    );
}

export default App;
